<template>
  <form>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-primary">
        <div class="card-icon">
          <md-icon>edit</md-icon>
        </div>
        <h4 class="title">Edit Administrator</h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-40 md-small-size-100">
            <div class="picture-container">
              <div class="picture">
                <div v-if="!image">
                  <img :src="avatar" class="picture-src" title="">
                </div>
                <div v-else>
                  <img :src="image" />
                </div>
                <input type="file" @change="onFileChange">
              </div>
              <h6 class="description">Choose Avatar</h6>
            </div>
            <div class="active-switch">
              <span class="switch-container">
                <md-switch v-model="active">Active</md-switch>
              </span>
            </div>
          </div>
          <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
            <md-field :class="[
              {'md-valid': !errors.has('name') && touched.name},
              {'md-error': errors.has('name')}]">
              <label>Name</label>
              <md-input
                v-model="name"
                data-vv-name="name"
                type="name"
                required
                v-validate="modelValidations.name">
              </md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('name') && touched.name">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('name') && touched.name">done</md-icon>
              </slide-y-down-transition>
            </md-field>
            <md-field :class="[
              {'md-valid': !errors.has('email') && touched.email},
              {'md-error': errors.has('email')}]">
              <label>Email Adress</label>
              <md-input
                v-model="email"
                data-vv-name="email"
                type="email"
                required
                v-validate="modelValidations.email">
              </md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('email') && touched.email">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('email') && touched.email">done</md-icon>
              </slide-y-down-transition>
            </md-field>
            <div class="form-category"><small>* Required fields</small></div>
            <md-button @click.native="$router.push({name: 'Change Administrator Password', params: {id: $route.params.id}})" class="md-sm md-default"><md-icon>vpn_key</md-icon>Change password</md-button>
          </div>
        </div>
      </md-card-content>

      <md-card-actions md-alignment="right">
        <md-button native-type="submit" @click.native.prevent="validate" class="md-success"><md-icon>save</md-icon>Save</md-button>
      </md-card-actions>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'

export default {
  components: {
    SlideYDownTransition
  },
  props: {
    administratorData: {
      type: Object
    }
  },
  data() {
    return {
      image: '',
      name: this.administratorData.name,
      email: this.administratorData.email,
      active: this.administratorData.active,
      avatar: this.administratorData.avatar,
      touched: {
        name: false,
        email: false
      },
      modelValidations: {
        name: {
          required: true,
        },
        email: {
          required: true,
          email: true
        }
      }
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then(isValid => {
        var administratorModel = {
          name: this.name,
          email: this.email,
          active: this.active,
          image: this.image
        }
        this.$emit('on-submit', administratorModel, isValid)
      })
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
      return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var reader = new FileReader();
      var vm = this;
      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  },
  watch: {
    name() {
      this.touched.name = true
    },
    email() {
      this.touched.email = true
    },
    administratorData() {
      this.name = this.administratorData.name
      this.email = this.administratorData.email
      this.active = this.administratorData.active
      this.avatar = this.administratorData.avatar
    }
  }
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions{
  border: none;
}
.picture-container{
  position: relative;
  cursor: pointer;
  text-align: center;
  margin-top: 4em;

  .description{
    margin: 0;
    margin-bottom: .5rem;
  }
}
.picture {
  width: 106px;
  height: 106px;
  background-color: #999999;
  border: 4px solid #CCCCCC;
  color: #FFFFFF;
  border-radius: 50%;
  margin: 5px auto;
  overflow: hidden;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;

  &:hover{
    border-color: #4caf50;
  }
}
.picture input[type="file"] {
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0 !important;
  position: absolute;
  top: 0;
  width: 100%;
}
.picture div {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.picture div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active-switch {
  margin-top: 40px;
  text-align: center;
  display: flex;
  .switch-container {
    margin: 0 auto;
    .md-switch {
      margin: 0;
    }
  }
}
</style>
